import {mapActions, mapGetters, mapMutations} from 'vuex';

import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'vacancy',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        letter: '',
        position: '',
        link: '',
        cv: '',
      },
      isValidPhone: false,
      validationErrors: {
        phone: []
      }
    }
  },
  validations() {
    if (this.isShowFormWithVacancy.position_field) {
      return {
        payload: {
          first_name: {
            required,
            maxLength: maxLength(50),
          },
          last_name: {
            required,
            maxLength: maxLength(50),
          },
          letter: {
            minLength: minLength(10),
            maxLength: maxLength(500),
          },
          link: {
            minLength: minLength(10),
            maxLength: maxLength(200),
          },
          position: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(80),
          },
          email: {
            required,
            maxLength: maxLength(60),
            email,
          },
          cv: {
            required
          },
        },
      }
    } else {
      return {
        payload: {
          first_name: {
            required,
            maxLength: maxLength(50),
          },
          last_name: {
            required,
            maxLength: maxLength(50),
          },
          letter: {
            minLength: minLength(10),
            maxLength: maxLength(500),
          },
          link: {
            minLength: minLength(10),
            maxLength: maxLength(200),
          },
          email: {
            required,
            maxLength: maxLength(60),
            email,
          },
          cv: {
            required,
          }
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      sendResumeLoading: 'vacancy/sendResumeLoading',
      config: 'setting/config',
      isShowFormWithVacancy: 'vacancy/isShowFormWithVacancy'
    }),
    firstNameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    positionErrors() {
      let error = [];
      if (!this.$v.payload.position.$dirty) {
        return error;
      }
      if (!this.$v.payload.position.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 80));
      }
      if (!this.$v.payload.position.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.position.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.position) {
        this.validationErrors.position.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    letterErrors() {
      let error = [];
      if (!this.$v.payload.letter.$dirty) {
        return error;
      }
      if (!this.$v.payload.letter.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (!this.$v.payload.letter.minLength) {
        error.push(this.$t('validationMin').replace(':count', 10));
      }
      if (this.validationErrors.letter) {
        this.validationErrors.letter.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    linkErrors() {
      let error = [];
      if (!this.$v.payload.link.$dirty) {
        return error;
      }
      if (!this.$v.payload.link.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 200));
      }
      if (!this.$v.payload.link.minLength) {
        error.push(this.$t('validationMin').replace(':count', 10));
      }
      if (this.validationErrors.link) {
        this.validationErrors.link.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cvErrors() {
      let error = [];
      if (!this.$v.payload.cv.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.cv) {
        this.validationErrors.cv.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    }
  },
  components: {
  },
  created() {
    setTimeout(()=> {
      this.clearForm();
      console.log('clear')
      this.validationErrors.cv = ['']
    },500)
  },
  methods: {
    ...mapActions({
      sendResume: `vacancy/SEND_RESUME`
    }),

    ...mapMutations({
      showSendConfirmRegistrationPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
      setDataToContentPopup: `system/POPUP_TEXT_CONTENT`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeShowVacancyPopup: `vacancy/CHANGE_SHOW_FORM_WITH_VACANCY`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    clearForm() {
      this.$v.$reset();
      this.payload.first_name = '';
      this.payload.last_name = '';
      this.payload.phone = '';
      this.payload.email = '';
      this.payload.link = '';
      this.payload.letter = '';
      this.payload.cv = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        var registerData = new FormData();
        registerData.append('first_name', this.payload.first_name);
        registerData.append('last_name', this.payload.last_name);
        registerData.append('phone', this.payload.phone.replace(/[\s.,%()-]/g, ''));
        registerData.append('email', this.payload.email);
        registerData.append('link', this.payload.link);
        registerData.append('letter', this.payload.letter);
        registerData.append('cv', this.payload.cv);
        if( this.isShowFormWithVacancy.vacancy ){
          registerData.append('vacancy', this.isShowFormWithVacancy.vacancy);
        }
        if( this.isShowFormWithVacancy.vacancy_id ){
          registerData.append('vacancy_id', this.isShowFormWithVacancy.vacancy_id);
        }
        this.sendResume(registerData)
          .then((response) => {

            this.changeShowVacancyPopup({show:false});
            this.$nextTick(()=>{
              this.changeContentPopup({
                text: this.$t('successCv'),
              });
            })
            setTimeout(()=> {
              this.changeShowContentPopup(true);
            },200)

          }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;
      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
        this.validationErrors.phone = [];
      }
    },
    changeValidationPhone() {
      this.validationErrors.phone = [];
      if (this.payload.phone.length > 0) {
        if (!this.isValidPhone) {
          this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
        } else {
          this.validationErrors.phone = [];
        }
      } else {
        this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
      }
    },
  },
  destroyed() {
    this.changeShowVacancyPopup({position_field: false,vacancy: '',vacancy_id: null})
  }
}
